import React, { Fragment } from 'react'
import './numbered-dl.sass'

const getListItem = (listItems) => {
  return listItems.map((item, i) => {
    return (
      <Fragment key={i}>
        <dt className="NumberedDl__title">
          <span className="NumberedDl__number">{i + 1}</span> {item.key}
        </dt>
        <dd className="NumberedDl__def">
          {item.value}
        </dd>
      </Fragment>
    )
  })
}

const NumberedDl = (props) => (
  <dl className="NumberedDl mb-0">
    {getListItem(props.listItems)}
  </dl>
)

export default NumberedDl
