import React from 'react'
import Image from 'components/image/image'
import { provideAuthorAssets } from './../../helpers/functions'
import './author.sass'

const Author = (props) => {
  if (!props.author) return null;

  const author = provideAuthorAssets(props.author)
  return (
    <address className="Author d-flex align-items-center m-0 pt-4 pb-3 pt-sm-0 text-light">
      <a
        href={author.linkedin_url}
        target="_blank"
        rel="author noopener noreferrer">
        <Image
          image_url={`avatars/${author.avatar}`}
          alt={author.name}
          className="Author__avatar"/>
      </a>
      <div className="ml-3 mr-4 d-sm-flex align-items-center">
        <div className="Author__content pr-sm-3">
          <a
            href={author.linkedin_url}
            target="_blank"
            rel="author noopener noreferrer"
            className={`${props.className} text-uppercase`}>
            {author.name}
          </a>
          <p className="m-0 small font-italic">
            {author.role}
          </p>
        </div>
        <div className="pl-sm-3 pt-2 pt-sm-0">
          <a
            className="mr-2"
            target="_blank"
            rel="noopener noreferrer"
            href={author.twitter_url}>
            <Image
              image_url="icons/icon-twitter.svg"
              alt="Twitter icon" />
          </a>
          <a
            className="mx-2"
            target="_blank"
            rel="noopener noreferrer"
            href={author.linkedin_url}>
            <Image
              image_url="icons/icon-linkedin.svg"
              alt="Linkedin icon" />
          </a>
        </div>
      </div>
    </address>
  )
}

export default Author
