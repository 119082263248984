import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import Hero from 'components/global/hero'
import Author from 'components/author/author'
import StickySidebar from 'components/sidebars/sticky-sidebar'
import ResourcesForm from 'components/forms/resources-form'
import ScrollButton from 'components/scroll-button/scroll-button'
import NumberedDl from 'components/lists/numbered-dl'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './templates.sass'

const Template = ({ data }) => {
  const d = data.allArticlesYaml.edges[0].node

  return (
    <Layout bodyClass="k-dark-hero-page">
      <SEO
        title={`${d.title} | Resources`}
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description}
        image={d.meta.image} />
      <div className="Resource">
        <Hero image={d.image} className="Hero--article" alt={`Background image for ${d.title}`}>
          <div className="container-fluid">
            <h1 className="mb-0 text-light">{d.title}</h1>
            <h2 className="mb-4 text-light font-weight-normal">{d.subtitle}</h2>
            <Author author={d.author} className="text-light" />
          </div>
          <ScrollButton
          to="#k-anchor__resource-form"
          className="btn btn-light text-uppercase d-md-none k-btn-absolute">
            Download paper
          </ScrollButton>
        </Hero>
        <div className="k-bg--sidebar">
          <main className="container-fluid">
            <div className="row">
              <div className="col k-bg--white">
                <section className="k-section-pt">
                  <blockquote className="text-center text-md-left mt-4 mt-md-0">
                    <p className="display-2 text-primary">{d.blockquote.quote}</p>
                    <cite>&mdash; {d.blockquote.author}</cite>
                  </blockquote>
                  <hr className="my-5" />
                  <p className="display-3 mb-0">{d.synopsis}</p>
                </section>
                <section className="k-section-pt">
                  <div className="mb-2"><h3 className="mb-4 mb-md-5">
                    What you'll learn
                  </h3></div>
                  <NumberedDl listItems={d.learnings} />
                </section>
                <section className="k-section">
                  <div
                    className="k-markdown-content"
                    dangerouslySetInnerHTML={{ __html: d.content }} />
                </section>
              </div>
              <aside className="col-lg-auto py-5 k-anchor__section k-bg--grey">
                <div id="k-anchor__resource-form" />
                <StickySidebar>
                  <h4 className="text-uppercase k-text--heading-linethrough">
                    <span className="k-bg--grey">Free download</span>
                  </h4>
                  <ResourcesForm resource={d.resource} />
                </StickySidebar>
              </aside>
            </div>
          </main>
        </div>
      </div>
      <GetInTouch />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    allArticlesYaml(filter: { path: { eq: $path } }) {
      edges {
        node {
          path
          meta {
            title
            keywords
            image
            description
          }
          resource {
            path
            name
            gtmFormId
          }
          title
          subtitle
          image
          author
          blockquote {
            quote
            author
          }
          synopsis
          learnings {
            key
            value
          }
          content
        }
      }
    }
  }
`

export default Template
